<template>
  <div>
    <v-dialog v-model="showHistory" width="500">
      <v-card>
        <v-container>
          <div class="text-center" v-if="loadingHistory">
            <v-progress-circular
              indeterminate
              size="25"
              width="2"
              color="primary"
            />
          </div>
          <template v-else>
            <v-timeline dense>
              <v-timeline-item small v-for="(item, i) in history" :key="i">
                <v-card class="elevation-2">
                  <div class="pt-4 pl-4 font-weight-bold">
                    <template v-if="item.action === 'new'">創建</template>
                    <template v-else-if="item.action === 'assign'"
                      >分派</template
                    >
                    <template v-else-if="item.action === 'save'">更新</template>
                    <template v-else-if="item.action === 'close'"
                      >完成</template
                    >
                    <template v-else-if="item.action === 'cancel'"
                      >取消</template
                    >
                    <template v-else>{{ item.action }}</template>
                  </div>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="3" v-if="item.job_ticket_assignee"
                        >處理人員</v-col
                      >
                      <v-col cols="9" v-if="item.job_ticket_assignee">{{
                        item.job_ticket_assignee
                      }}</v-col>
                      <v-col cols="3" v-if="item.job_ticket_name">名稱</v-col>
                      <v-col cols="9" v-if="item.job_ticket_name">{{
                        item.job_ticket_name
                      }}</v-col>
                      <v-col cols="3" v-if="item.job_ticket_content"
                        >內容</v-col
                      >
                      <v-col cols="9" v-if="item.job_ticket_content">{{
                        item.job_ticket_content
                      }}</v-col>
                      <v-col cols="3" v-if="item.job_ticket_attached_files_uri"
                        >附加檔案</v-col
                      >
                      <v-col
                        cols="9"
                        v-if="item.job_ticket_attached_files_uri"
                        >{{ item.job_ticket_attached_files_uri }}</v-col
                      >
                      <v-col cols="3" v-if="item.job_ticket_process_description"
                        >處理說明</v-col
                      >
                      <v-col
                        cols="9"
                        v-if="item.job_ticket_process_description"
                        >{{ item.job_ticket_process_description }}</v-col
                      >
                      <v-col cols="3" v-if="item.job_ticket_reporter"
                        >申告人</v-col
                      >
                      <v-col cols="9" v-if="item.job_ticket_reporter">{{
                        item.job_ticket_reporter
                      }}</v-col>
                      <v-col cols="3" v-if="item.job_ticket_reporter_email"
                        >聯絡信箱</v-col
                      >
                      <v-col cols="9" v-if="item.job_ticket_reporter_email">{{
                        item.job_ticket_reporter_email
                      }}</v-col>
                      <v-col cols="3" v-if="item.job_ticket_reporter_telephone"
                        >連絡電話</v-col
                      >
                      <v-col
                        cols="9"
                        v-if="item.job_ticket_reporter_telephone"
                        >{{ item.job_ticket_reporter_telephone }}</v-col
                      >
                      <v-col cols="3" v-if="item.job_ticket_school_id"
                        >學校</v-col
                      >
                      <v-col cols="9" v-if="item.job_ticket_school_id">{{
                        item.job_ticket_school_id
                      }}</v-col>
                      <v-col cols="3" v-if="item.modified_date">時間</v-col>
                      <v-col cols="9" v-if="item.modified_date">{{
                        moment(item.modified_date).format("YYYY-MM-DD hh:mm")
                      }}</v-col>
                      <v-col cols="3" v-if="item.who">操作人員</v-col>
                      <v-col cols="9" v-if="item.who">{{ item.who }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <!-- <v-expansion-panels focusable>
              <v-expansion-panel v-for="(item, i) in history" :key="i">
                <v-expansion-panel-header>
                  <template v-if="item.action === 'new'">創建</template>
                  <template v-else-if="item.action === 'assign'">分派</template>
                  <template v-else-if="item.action === 'save'">更新</template>
                  <template v-else-if="item.action === 'close'">完成</template>
                  <template v-else-if="item.action === 'cancel'">取消</template>
                  <template v-else>{{ item.action }}</template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-row no-gutters>
                    <v-col cols="3" v-if="item.job_ticket_assignee"
                      >處理人員</v-col
                    >
                    <v-col cols="9" v-if="item.job_ticket_assignee">{{
                      item.job_ticket_assignee
                    }}</v-col>
                    <v-col cols="3" v-if="item.job_ticket_name">名稱</v-col>
                    <v-col cols="9" v-if="item.job_ticket_name">{{
                      item.job_ticket_name
                    }}</v-col>
                    <v-col cols="3" v-if="item.job_ticket_content">內容</v-col>
                    <v-col cols="9" v-if="item.job_ticket_content">{{
                      item.job_ticket_content
                    }}</v-col>
                    <v-col cols="3" v-if="item.modified_date">時間</v-col>
                    <v-col cols="9" v-if="item.modified_date">{{
                      moment(item.modified_date).format("YYYY-MM-DD hh:mm")
                    }}</v-col>
                    <v-col cols="3" v-if="item.who">操作人員</v-col>
                    <v-col cols="9" v-if="item.who">{{ item.who }}</v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->
          </template>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAssign" width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">指定處理人員</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-select label="分派人員" :items="assignee"></v-select> -->
            <v-text-field v-model="assignee" label="處理人員" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="doAssign"> 儲存 </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAction" width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">處理動作</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-select label="分派人員" :items="assignee"></v-select> -->
            <v-select
              v-model="selectAction"
              label="選擇動作"
              :items="actionsList"
              item-text="text"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="doAction"> 儲存 </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTicket" width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">報修單</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="標題" v-model="selectItem.name" />
            <v-text-field label="狀況說明" v-model="selectItem.content" />
            <v-text-field
              label="處理進度說明"
              v-model="selectItem.process_description"
            />
            <v-text-field label="申告人" v-model="selectItem.reporter" />
            <v-text-field label="email" v-model="selectItem.reporter_email" />
            <v-text-field
              label="電話"
              v-model="selectItem.reporter_telephone"
            />
            <v-text-field label="縣市" v-model="selectItem.area_id" />
            <v-text-field label="學校" v-model="selectItem.school_id" />
            <!-- <div>{{ selectItem.attached_files_uri }}</div> -->
            <div class="mb-1" v-if="selectItem.attached_files_uri">附檔</div>
            <div v-for="file in filesList(selectItem.attached_files_uri)">
              <v-icon color="light-blue lighten-4">mdi-paperclip</v-icon>
              <a :href="file">{{
                file.split("/job-ticket-attached-files/")[1]
              }}</a>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-file-input
                ref="fileInput"
                multiple
                style="display: none"
                @change="uploadFile"
                v-model="files"
              />
              <v-btn
                color="light-blue darken-1"
                dark
                @click="$refs.fileInput.$refs.input.click()"
              >
                <v-icon>mdi-upload</v-icon>
                上傳附檔
              </v-btn>
              <v-btn color="primary" @click="updateTicket">
                {{ mode === "create" ? "儲存" : "修改" }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex">
      <div class="text-h6 font-weight-bold">工單管理</div>
    </div>
    <v-divider class="my-4" />
    <v-row>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="日期"
              readonly
              v-bind="attrs"
              v-on="on"
              solo
              hide-details
              clearable
              @click:clear="selectDate = []"
            >
              <template v-slot:label>
                <div><span class="red--text">*</span>日期</div>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="selectDate"
            no-title
            color="primary"
            :show-current="false"
            range
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-text-field
          label="縣市"
          v-model="searchCounty"
          solo
          background-color="white"
          hide-details
          clearable
        />
      </v-col>
      <v-col>
        <v-text-field
          label="學校"
          v-model="searchSchool"
          solo
          background-color="white"
          hide-details
          clearable
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="5">
        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="search(true)"
              :disabled="!dateRangeText"
              >查詢</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="download"
              block
              outlined
              color="primary"
              :disabled="!items.length || !dateRangeText"
            >
              <template v-if="loadingDownload">
                <v-progress-circular
                  indeterminate
                  size="25"
                  width="2"
                  color="primary"
                ></v-progress-circular>
              </template>
              <template v-else>
                <v-icon>mdi-download</v-icon>
                下載報表
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      class="mt-5 elevation-2"
      hide-default-footer
      :headers="headers"
      :items="items"
      :page.sync="page"
      @page-count="pageCount = $event"
      :loading="loading"
      loading-text="資料載入中..."
      no-data-text="( 無資料 )"
    >
      <template v-slot:item.start_date="{ item }">
        {{ moment(item.start_date).format("YYYY-MM-DD hh:mm") }}
      </template>
      <template v-slot:item.last_modified_date="{ item }">
        {{ moment(item.last_modified_date).format("YYYY-MM-DD hh:mm") }}
      </template>
      <template v-slot:item.history="{ item }">
        <v-icon color="primary" @click="displayHistory(item.sn)"
          >mdi-history</v-icon
        >
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip color="warning" v-if="item.state === 'to_be_assigned'"
          >待指派</v-chip
        >
        <v-chip color="info" v-else-if="item.state === 'to_be_processed'"
          >處理中</v-chip
        >
        <v-chip v-else-if="item.state === 'canceled'">已取消</v-chip>
        <v-chip color="success" v-else-if="item.state === 'closed'"
          >已結案</v-chip
        >
        <v-chip color="warning" v-else-if="item.state === 'to_be_co_worked'"
          >草稿</v-chip
        >
        <v-chip v-else-if="item.state === 'draft'">草稿</v-chip>
        <v-chip v-else>{{ item.state }}</v-chip>
      </template>
      <template v-slot:item.expand="{ item }">
        <span class="class font-weight-bold">
          {{ item.expand ? "Y" : "N" }}
        </span>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-btn
          text
          color="primary"
          @click="
            showTicket = true;
            mode = 'edit';
            Object.assign(selectItem, item);
          "
          >編輯</v-btn
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          color="primary"
          v-if="item.state === 'to_be_assigned'"
          @click="
            Object.assign(selectItem, item);
            showAssign = true;
          "
          >分派</v-btn
        >
        <v-btn
          color="primary"
          v-else
          @click="
            Object.assign(selectItem, item);
            showAction = true;
          "
          >處理</v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>
    <!-- <v-row justify="end" class="mt-15">
      <v-col cols="3">
        <v-select v-model="identity" :items="identityList" outlined></v-select>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import Ticket from "@/api/Ticket";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dateMenu: false,
      selectDate: [],
      headers: [
        { text: "報修編號", value: "sn" },
        { text: "名稱", value: "name" },
        { text: "狀況說明", value: "content" },
        // { text: "創建者", value: "creator" },
        { text: "申告日期", value: "start_date" },
        { text: "處理人員", value: "assignee" },
        // { text: "地點", value: "area_id" },
        { text: "處理進度", value: "state", align: "center" },
        { text: "最後更新日期", value: "last_modified_date" },
        // { text: "處理說明", value: "notes" },
        // { text: "是否展延", value: "expand", align: "center" },
        { text: "", value: "history", width: 50 },
        { text: "", value: "detail", width: 50 },
        { text: "", value: "action", width: 50 },
      ],
      items: [],
      page: 1,
      pageCount: 0,
      loading: false,

      showAction: false,
      showAssign: false,
      selectItem: {},
      actionsList: [
        { text: "完成", value: "close" },
        { text: "取消", value: "cancel" },
      ],
      selectAction: "",

      identity: "開案人員",
      identityList: ["開案人員", "分派人員", "處理人員"],
      assignee: "",

      showTicket: false,
      mode: "create",

      loadingDownload: false,

      files: [],

      searchCounty: "",
      searchSchool: "",

      showHistory: false,
      loadingHistory: false,
      history: [],
    };
  },
  computed: {
    dateRangeText() {
      if (this.selectDate[1]) {
        let firstDate = new Date(this.selectDate[0]);
        let secondDate = new Date(this.selectDate[1]);
        return firstDate < secondDate
          ? `${this.selectDate[0]} ~ ${this.selectDate[1]}`
          : `${this.selectDate[1]} ~ ${this.selectDate[0]}`;
      } else return this.selectDate[0];
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    ...mapActions({
      setLoadingOverlay: "settings/setLoadingOverlay",
    }),
    async search(condition = false) {
      this.loading = true;
      try {
        let params = {};
        if (this.searchCounty) params.area_id = this.searchCounty;
        if (this.searchSchool) params.school_id = this.searchSchool;
        console.log(params);
        const { data } = condition
          ? await Ticket.search(
              this.dateRangeText.split(` ~ `)[0],
              this.dateRangeText.split(` ~ `)[1],
              params
            )
          : await Ticket.get();
        console.log(data);
        this.items = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async updateTicket() {
      try {
        this.setLoadingOverlay(true);
        const createRes = await Ticket.patchTicket(this.selectItem.sn, {
          attached_files_uri: this.selectItem.attached_files_uri,
          area_id: this.selectItem.area_id,
          content: this.selectItem.content,
          name: this.selectItem.name,
          process_description: this.selectItem.process_description,
          reporter: this.selectItem.reporter,
          reporter_email: this.selectItem.reporter_email,
          reporter_telephone: this.selectItem.reporter_telephone,
          school_id: this.selectItem.school_id,
        });
        console.log(createRes);
        this.search();
      } catch (err) {
        console.error(err.response);
      } finally {
        this.showTicket = false;
        this.setLoadingOverlay(false);
      }
    },
    // async createDraft() {
    //   try {
    //     const draftRes = await Ticket.draft(
    //       this.selectItem.name,
    //       this.selectItem.content
    //     );
    //     console.log(draftRes);
    //     this.search();
    //   } catch (err) {
    //     console.error(err);
    //   } finally {
    //     this.showTicket = false;
    //   }
    // },
    async download() {
      this.loadingDownload = true;
      try {
        const { data: downloadUrl } = await Ticket.getFile(
          this.dateRangeText.split(` ~ `)[0],
          this.dateRangeText.split(` ~ `)[1]
        );
        console.log(downloadUrl);

        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        link.href = "";
        URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingDownload = false;
      }
    },
    filesList(filesString) {
      return filesString?.split(";").slice(0, -1);
    },
    async uploadFile() {
      try {
        this.setLoadingOverlay(true);
        let formData = new FormData();
        this.files.forEach((element, index) => {
          formData.append("Test-File-1[" + index + "]", element);
        });
        const { data: uploadRes } = await Ticket.uploadFiles(formData);
        console.log(uploadRes);
        if (uploadRes) {
          if (this.selectItem.attached_files_uri)
            this.selectItem.attached_files_uri += uploadRes;
          else this.selectItem.attached_files_uri = uploadRes;
        }
        console.log(this.selectItem);
        await Ticket.patchTicket(this.selectItem.sn, {
          attached_files_uri: this.selectItem.attached_files_uri,
          // area_id: this.selectItem.area_id,
          // content: this.selectItem.content,
          // name: this.selectItem.name,
          // process_description: this.selectItem.process_description,
          // reporter: this.selectItem.reporter,
          // reporter_email: this.selectItem.reporter_email,
          // reporter_telephone: this.selectItem.reporter_telephone,
          // school_id: this.selectItem.school_id,
        });
        Swal.fire({
          title: "上傳成功",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          width: 250,
        });
        this.files = [];
      } catch (err) {
        console.error(err.response);
        Swal.fire({
          title: "上傳失敗",
          icon: "error",
          confirmButtonColor: "#3085d6",
          width: 300,
        });
      } finally {
        this.setLoadingOverlay(false);
      }
    },
    async doAssign() {
      try {
        this.setLoadingOverlay(true);
        await Ticket.process(this.selectItem.sn, "assign", {
          assignee: this.assignee,
        });
        Swal.fire({
          title: "分派成功",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          width: 250,
        });
        this.showAssign = false;
        this.assignee = "";
        this.search();
      } catch (err) {
        console.error(err.response);
        Swal.fire({
          title: "分派失敗",
          icon: "error",
          confirmButtonColor: "#3085d6",
          width: 300,
        });
      } finally {
        this.setLoadingOverlay(false);
      }
    },
    async doAction() {
      try {
        this.setLoadingOverlay(true);
        await Ticket.process(this.selectItem.sn, this.selectAction, {});
        Swal.fire({
          title: "狀態已更新",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          width: 250,
        });
        this.showAction = false;
        this.selectAction = "";
        this.search();
      } catch (err) {
        console.error(err.response);
        Swal.fire({
          title: "操作失敗",
          icon: "error",
          confirmButtonColor: "#3085d6",
          width: 300,
        });
      } finally {
        this.setLoadingOverlay(false);
      }
    },
    async displayHistory(sn) {
      try {
        this.showHistory = true;
        this.loadingHistory = true;
        const { data: historyRes } = await Ticket.history(sn);
        console.log(historyRes);
        this.history = historyRes;
      } catch (err) {
        console.error(err);
        Swal.fire({
          title: "查詢工單歷程失敗，請稍後再試",
          icon: "error",
          confirmButtonColor: "#3085d6",
          width: 300,
        }).then((result) => {
          if (result.isConfirmed) {
            this.showHistory = false;
          }
        });
      } finally {
        this.loadingHistory = false;
      }
    },
  },
};
</script>
